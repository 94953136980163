@import '~common-next/styles/functions';

.legacy-signin {
    padding-top: to-rem(20px);
    height: to-rem(76px);
    position: relative;

    &__button {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: flex-start;

        > img {
            position: relative;
            top: to-rem(12px);
            width: to-rem(45px);
        }
    }

    &__icon {
        display: inline-block;
        align-self: center;
        margin-bottom: to-rem(4px);
    }

    &__copy {
        display: inline-block;
        color: var(--sparky-color-neutral-800);
        text-align: left;
        font-family: var(--legacy-header-font-family);
        font-size: var(--sparky-font-size-14) !important;
        margin-left: 1rem;

        & a {
            color: var(--sparky-color-neutral-800);
            text-decoration: none;

            &:hover {
                color: var(--sparky-theme-color-primary-foreground);
            }
        }

        & span {
            font-weight: 600;
            font-size: 1.75rem;
            font-size: var(--sparky-font-size-18) !important;
            padding-bottom: to-rem(3px);
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        z-index: var(--sparky-z-index-500) !important;
        overflow: hidden;
        background-color: var(--sparky-color-neutral-white);
        top: to-rem(76px);
        right: -2rem;
        width: to-rem(320px);
        border-radius: 0.4rem;
        padding: 0 !important;

        .legacy-signin:hover & {
            display: block;
        }

        .loyalty-account-menu-enabled & {
            width: to-rem(345px) !important;
            border-radius: to-rem(3px) !important;
            box-shadow: 0 0 to-rem(16px) rgb(0 0 0 / 15%) !important;
            top: auto;
            right: auto;
        }

        .signin-dropcontent__container {
            background-position: top;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('https://s7d2.scene7.com/is/image/PetSmart/LY_5_CompleteProfileSuccessModal_ConfettiBackground_allsize_0611');

            .loyalty-account-menu-enabled & {
                background: none;
            }
        }

        .signin-dropcontent__logo {
            margin-top: to-rem(25px);
        }

        .sparky-c-logo--rewards {
            width: to-rem(180px);
            display: block;
            margin: to-rem(32px) auto to-rem(20px);
        }

        .signin-dropcontent__content {
            color: #003b49;
            font-size: var(--sparky-font-size-14) !important;
            line-height: to-rem(20px);
            font-family: var(--legacy-header-font-family);
            text-align: center;
            padding: 0;
            display: flex;
            flex-direction: column;

            .loyalty-account-menu-enabled & {
                padding: 0 to-rem(35px) to-rem(13px);
                padding-right: to-rem(34px);
            }

            p {
                margin-top: 0;
                order: 0;
                padding: to-rem(14px) to-rem(25px) 0;
                margin-bottom: 0;

                .loyalty-account-menu-enabled & {
                    margin-bottom: to-rem(20px);
                    padding: 0;
                }
            }

            a {
                font-weight: bold;
            }
        }

        .signin-dropcontent__button {
            display: block;
            padding: to-rem(15px) 0;
            background-color: var(--sparky-theme-color-primary-foreground);
            color: var(--sparky-color-neutral-white);
            order: 2;
            margin-top: to-rem(25px);
            border-radius: 0;

            .loyalty-account-menu-enabled & {
                padding: to-rem(12px) 0;
                margin-bottom: to-rem(8px);
                margin-top: 0;
                order: 1;
            }

            &:hover {
                color: var(--sparky-color-neutral-white);
                background-color: var(--sparky-color-brand-blue-600);
            }
        }

        .sparky-c-text-link {
            color: var(--sparky-theme-color-primary-foreground);
            order: 1;

            .loyalty-account-menu-enabled & {
                line-height: to-rem(14px);
                order: 2;
                border: to-rem(1px) solid #acacac;
                padding: to-rem(12px) 0;
                display: block;
                border-radius: to-rem(3px);
            }
        }
    }

    &__accent {
        display: none;
        border: to-rem(12px) solid var(--sparky-color-neutral-white);
        border-right-color: transparent;
        border-bottom-color: transparent;
        box-shadow: to-rem(-3px) to-rem(-3px) to-rem(4px) rgb(0 0 0 / 5%);
        position: absolute;
        left: 50%;
        top: to-rem(65px);
        transform: rotate(45deg) skew(-10deg, -10deg);
        z-index: var(--sparky-z-index-500) !important;

        .legacy-signin:hover & {
            display: block;
        }
    }
}
